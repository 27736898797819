import {
  Divider,
  Grid,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import pattern from "patternomaly";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { chartContext } from "../../common/ChartContex";
import { patternsObj } from "../../common/formatter";
import Tooltip from "@mui/material/Tooltip";
import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

Chart.register(ChartDataLabels);
export default function EUETSCostsChart({
  customClass,
  textBlack,
  compliance_myEUETSCostsOptions,
  eu_chartOption,
  show,
  textColor,
  block,
}) {
  const chartData = useContext(chartContext);
  useEffect(() => {}, [chartData]);

  const [keyCompilance, setKeyCompilance] = useState([]);
  const [valueCompilance, setValueCompilance] = useState([]);
  const [showChart, setShowChart] = useState(false);

  const [headerData, setHeaderData] = useState();
  const [fuelData, setFuelData] = useState();

  const [keyCompilanceEuChart, setKeyCompilanceEuChart] = useState([]);
  const [valueCompilanceEuChart, setValueCompilanceEuChart] = useState([]);

  const [allData, setAllData] = useState([]);

  const [tableData, setTableData] = useState();

  const compliance_myEUETSCostsChart = chartData?.myEUETSCosts;
  const eu_chart = chartData?.myCOCchart;

  useEffect(() => {
    if (chartData?.myCOCchart) {
      // EUTS cost chart
      const compliance_myEUETSCostsChart = chartData?.myEUETSCosts;
      const eu_chart = chartData?.myCOCchart;

      if (chartData?.myEUETSCosts !== null) {
        setShowChart(true);
        const allowanceCost_new =
          compliance_myEUETSCostsChart?.allowanceCost_v2;

        const yearEu = allowanceCost_new[0].points.map((y) => y.year);

        const chartDataEU = allowanceCost_new.map((item) => ({
          label: item.vesselName,
          backgroundColor: item.colorHex,
          data: item.points.map((point) => point.value.toFixed(2)),
        }));

        setKeyCompilance(yearEu);
        setValueCompilance(chartDataEU);
      }

      // my coc chart

      const myCOCChart = eu_chart?.myCOCchart;
      const newLegends = eu_chart?.newLegends;

      setTableData(newLegends);

      const fuelsData = Object.keys(newLegends);

      setFuelData(fuelsData);
      setHeaderData(eu_chart?.legendHeadings);

      const shipNamesEuChart = Object.keys(myCOCChart);

      const labels = Object.keys(myCOCChart[shipNamesEuChart[0]]);

      const yearsEuChart = Object.keys(myCOCChart[shipNamesEuChart[0]]);

      const datasetsEuChart = [];

      const fuelTypes = Array.from(new Set(Object.keys(myCOCChart)));
      fuelTypes.forEach((fuelType, index) => {
        let stackIndex = 0;
        const propulsionCosts = [];
        const complianceCosts = [];
        const nonComplianceCredits = [];
        const etsallowances = [];

        const propulsionLegendInfoNew = newLegends[fuelType]?.propulsion || {};
        const complianceLegendInfoNew = newLegends[fuelType]?.compliance || {};
        const nonComplianceCreditsInfoNew =
          newLegends[fuelType]?.["EU-FM non-compliance credits"] || {};
        const etsallowancesInfoNew = newLegends[fuelType]?.["ETS costs"] || {};

        labels.forEach((year) => {
          propulsionCosts.push(myCOCChart[fuelType][year]["propulsionCost"]);
          complianceCosts.push(myCOCChart[fuelType][year]["complianceCost"]);
          nonComplianceCredits.push(
            myCOCChart[fuelType][year]["nonComplianceCredits"]
          );
          etsallowances.push(myCOCChart[fuelType][year]["etsallowances"]);
        });

        datasetsEuChart.push(
          {
            label: `${fuelType} propulsion`,
            type: "bar",
            stack: fuelType,
            data: propulsionCosts,
            index: index,
            stackIndex: stackIndex++,
            backgroundColor:
              propulsionLegendInfoNew?.pattern === "solid"
                ? propulsionLegendInfoNew?.myColor
                : pattern.draw(
                    patternsObj[propulsionLegendInfoNew?.pattern],
                    propulsionLegendInfoNew?.myColor
                  ),
          },
          {
            label: `${fuelType} compliance`,
            type: "bar",
            stack: fuelType,
            data: complianceCosts,
            index: index,
            stackIndex: stackIndex++,
            backgroundColor:
              complianceLegendInfoNew?.pattern === "solid"
                ? complianceLegendInfoNew?.myColor
                : pattern.draw(
                    patternsObj[complianceLegendInfoNew?.pattern],
                    complianceLegendInfoNew?.myColor
                  ),
          },
          {
            label: `${fuelType} EU-FM non-compliance credits`,
            type: "bar",
            stack: fuelType,
            data: nonComplianceCredits,
            index: index,
            stackIndex: stackIndex++,
            backgroundColor:
              nonComplianceCreditsInfoNew?.pattern === "solid"
                ? nonComplianceCreditsInfoNew?.myColor
                : pattern.draw(
                    patternsObj[nonComplianceCreditsInfoNew?.pattern],
                    nonComplianceCreditsInfoNew?.myColor
                  ),
          },
          {
            label: `${fuelType} ETS costs`,
            type: "bar",
            stack: fuelType,
            data: etsallowances,
            index: index,
            stackIndex: stackIndex++,
            backgroundColor:
              etsallowancesInfoNew?.pattern === "solid"
                ? etsallowancesInfoNew?.myColor
                : pattern.draw(
                    patternsObj[etsallowancesInfoNew?.pattern],
                    etsallowancesInfoNew?.myColor
                  ),
          }
        );
      });

      setKeyCompilanceEuChart(yearsEuChart);
      setValueCompilanceEuChart(datasetsEuChart);
      setAllData(datasetsEuChart);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [data, setData] = useState(null);

  const [open1, setOpen1] = useState(false);
  const [title1, setTitle1] = useState("Chart Information");
  const [data1, setData1] = useState(null);

  const handleCompilanceEuCHart = (title) => {
    setTitle(title);
    setOpen(!open);
    setData(compliance_myEUETSCostsChart.comments);
  };

  const handleEuChart = (title) => {
    setTitle1(title);
    setData1(eu_chart.comments);
    setOpen1(!open1);
  };

  useEffect(() => {}, [title]);

  const handleChartData = (e,data) => {
    const dataExists = valueCompilanceEuChart.some(
      (dataPoint) => dataPoint.label === data
    );

    if (dataExists) {
       e.classList.remove('active');
      const filteredValueComplianceChart = valueCompilanceEuChart.filter(
        (item) => item.label !== data
      );
      setValueCompilanceEuChart(filteredValueComplianceChart);
    } else {
      e.classList.add('active');
      const prevData = allData.filter((item) => item.label === data);

      const updatedChart = [...valueCompilanceEuChart, ...prevData];
  
      setValueCompilanceEuChart(updatedChart);
    }
  };

  const filteredValueCompilanceEuChart = valueCompilanceEuChart.map(
    (dataset) => {
      const filteredData = dataset.data.map((value) => Math.max(value, 0)); // Ensure only non-negative values
      return { ...dataset, data: filteredData };
    }
  );
  filteredValueCompilanceEuChart.sort((a, b) => {
    if (a.index === b.index) {
        return a.stackIndex - b.stackIndex;
    }
    return a.index - b.index;
  });

  const handleClose = () => {
    setOpen(false);
    euEftChart?.current?.scrollIntoView({ behavior: "auto" });
  };
  const handleClose2 = () => {
    setOpen1(false);
    euEtsChart2?.current?.scrollIntoView({ behavior: "auto" });
  };

  const euEftChart = useRef(null);
  const euEtsChartInfoRef = useRef(null);
  const euEtsChart2 = useRef(null);
  const euEtsInfoChart2 = useRef(null);

  useEffect(() => {
    if (open) {
      euEtsChartInfoRef?.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);

  useEffect(() => {
    if (open1) {
      euEtsInfoChart2?.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [open1]);

  return (
    <>
      {showChart && (
        <Grid
          ref={euEftChart}
          container
          className={`px-4 pt-7 pb-[24.57px] rounded-2xl bg-new-text ${customClass}`}
        >
          <Box className="flex justify-between w-full">
            <Box>
              <Typography
                variant="subtitle1"
                className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
              >
                {compliance_myEUETSCostsChart?.chartTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
              >
                {compliance_myEUETSCostsChart?.chartSubTitle}
              </Typography>
            </Box>

            {compliance_myEUETSCostsChart?.comments && show && (
              <Box>
                <Tooltip title="Notes and Sources" arrow>
                  <Button
                    type="submit"
                    onClick={() => {
                      handleCompilanceEuCHart(
                        compliance_myEUETSCostsChart?.chartTitle
                      );
                    }}
                  >
                    <img src={open ? UPImg : BottomImg} alt="Information" />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
          <Divider className="w-full border border-solid border-mortar-grey my-4" />
          {chartData ? (
            <Box className="w-full h-[600px]">
              <Bar
                data={{ labels: keyCompilance, datasets: valueCompilance }}
                options={compliance_myEUETSCostsOptions}
              />
            </Box>
          ) : (
            "feiching"
          )}
          {open && (
            <div ref={euEtsChartInfoRef}>
              <Divider className="w-full border border-solid border-mortar-grey my-4" />
              <div className="log-outmodal info">
                <div className="info-header">
                  <Grid className="flex justify-start items-start ">
                    <Typography
                      variant="h3"
                      className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                    >
                      {title}
                    </Typography>
                  </Grid>
                  <Button onClick={handleClose}>
                    <img src={CloseImg} alt="close" />
                  </Button>
                </div>
                {data && (
                  <div
                    className="text-base mt-[10px] info-text text-white"
                    dangerouslySetInnerHTML={{ __html: data }}
                  />
                )}
              </div>
            </div>
          )}
        </Grid>
      )}

      <Grid
        ref={euEtsChart2}
        container
        className={`px-4 pt-7 pb-[24.57px] mt-[30px] rounded-2xl bg-new-text ${customClass}`}
      >
        <Box className="flex justify-between w-full">
          <Box>
            <Typography
              variant="subtitle1"
              className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
            >
              {eu_chart?.chartTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {eu_chart?.chartSubTitle}
            </Typography>
          </Box>
          {eu_chart?.comments && show && (
            <Box>
              <Tooltip title="Notes and Sources" arrow>
                <Button
                  type="submit"
                  onClick={() => {
                    handleEuChart(eu_chart?.chartTitle);
                  }}
                >
                  <img src={open1 ? UPImg : BottomImg} alt="Information" />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider className="w-full border border-solid border-mortar-grey my-4" />
        {chartData ? (
          <>
            <Box className="w-full h-[600px]">
              <Bar
                data={{
                  labels: keyCompilanceEuChart,
                  datasets: filteredValueCompilanceEuChart,
                }}
                options={eu_chartOption}
              />
            </Box>
            <Grid className={`flex justify-center w-full ${block}`}>
              <Box className="flex justify-center">
                {
                  <Table
                    aria-label="simple table"
                    style={{
                      width: "85%",
                      borderCollapse: "separate",
                      borderSpacing: "2rem 1rem",
                    }}
                  >
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell className="border-0"></TableCell>

                          {headerData?.map((headerItem, index) => (
                            <TableCell
                              key={index}
                              className={`font-medium text-title text-[13px] leading-[15px] text-center  border-0 ${textColor}`}
                            >
                              {headerItem}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {fuelData?.map((fuelItems, index) => (
                          <TableRow
                            key={fuelItems}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component=""
                              scope="row"
                              className={`font-medium text-title text-[12px] leading-[13px] border-0   ${textColor} w-[16rem] text-left p-0`}
                            >
                              {fuelItems}
                            </TableCell>

                            {headerData?.map((item, index) => (
                              <TableCell
                                key={index}
                                className={`font-bold text-[13px] leading-[13px] border-0  text-center cursor-pointer MuiTableCell-sizeSmall; p-0`}
                              >
                                <div className="customLegendBorder active"
                                    onClick={(e) => {
                                      const newData = fuelItems + " " + item;
                                      handleChartData(e.target,newData);
                                    }}>
                                  <div
                                    style={{
                                      width: "44px",
                                      height: "12px",
                                      margin: "auto",
                                      backgroundColor: `${
                                        tableData[fuelItems][item]?.myColor
                                          ? tableData[fuelItems][item]?.myColor
                                          : ""
                                      }`,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      const newData = fuelItems + " " + item;
                                      handleChartData(e.target.parentElement, newData);
                                    }}
                                  ></div>
                                </div>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                }
              </Box>
            </Grid>
          </>
        ) : (
          "feiching"
        )}
        {open1 && (
          <div ref={euEtsInfoChart2}>
            <Divider className="w-full border border-solid border-mortar-grey my-4" />
            <div className="log-outmodal info">
              <div className="info-header">
                <Grid className="flex justify-start items-start ">
                  <Typography
                    variant="h3"
                    className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                  >
                    {title1}
                  </Typography>
                </Grid>

                <Button onClick={handleClose2}>
                  <img src={CloseImg} alt="close" />
                </Button>
              </div>
              {data1 && (
                <div
                  className="text-base mt-[10px] info-text text-white"
                  dangerouslySetInnerHTML={{ __html: data1 }}
                />
              )}
            </div>
          </div>
        )}
      </Grid>
    </>
  );
}
