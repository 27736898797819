import React, { useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthContext";
import SessionContext from "../contexts/SessionContext";

import AppNavigation from "./AppNavigation";
import AuthNavigation from "./AuthNavigation";

const MainNavigator = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const { user } = useContext(SessionContext);

  const userData = localStorage.getItem("accessToken");

  useEffect(() => {
    if (userData && user) setIsLoggedIn(true);
    else setIsLoggedIn(false);
  }, [setIsLoggedIn, userData, user]);

  return <>{isLoggedIn ? <AppNavigation /> : <AuthNavigation />}</>;
};

export default MainNavigator;
