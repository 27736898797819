import { Box, Container, Grid, Typography } from "@mui/material";
import { Chart } from "chart.js";
import React, { forwardRef, useContext, useState } from "react";
import Financial from "./results/Financial";
import Emissions from "./results/Emisssions";
import Compliance from "./results/Compliance";

import { chartContext } from "../common/ChartContex";
import { useEffect } from "react";
import EUETSCostsChart from "./results/EUETSCostsChart";

const Pdf = forwardRef(
  ({ componentRef1, componentRef2, componentRef3, componentRef4 }, ref) => {
    const chartData = useContext(chartContext);
    useEffect(() => {}, [chartData]);

    const [show, setShow] = useState(false);

    // options

    const compliance_myEUETSCostsOptionsPdf = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
          font: {
            size: 15,
            weight: 500,
            lineHeight: 15,
          },
        },
        legend: {
          position: "bottom",
          boxBorderStyle: "none",
          labels: {
            padding: 30,
            color: "#000",

            font: {
              size: 15,
              lineHeight: 14,
            },
            generateLabels: (chart) => {
              const originalLabels =
                Chart.defaults.plugins.legend.labels.generateLabels(chart);
              originalLabels.forEach((label) => {
                label.lineWidth = 0;
              });
              return originalLabels;
            },
          },
        },
      },
      tension: 0.1,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: "#fff",
          },
          ticks: {
            color: "#000",
            font: {
              size: 15,
            },
            lineHeight: "13px",
            callback: function (value, index, ticks) {
              if (value === 0) {
                return "$";
              } else {
                return "$" + value.toFixed(1).toLocaleString();
              }
            },
          },
          title: {
            display: true,
            text: chartData?.myEUETSCosts?.y_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
        x: {
          grid: {
            color: "#fff",
          },
          ticks: {
            color: "#000",
            font: {
              size: 15,
            },
            lineHeight: "13px",
          },
          title: {
            display: true,
            text: chartData?.myEUETSCosts?.x_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
      },
    };

    const yearlyFuelExpenditureOptionsPdf = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
          font: {
            size: 15,
            weight: 500,
            lineHeight: 15,
          },
        },
        legend: {
          position: "bottom",
          boxBorderStyle: "none",
          labels: {
            padding: 20,
            color: "#000",

            font: {
              size: 17,
              lineHeight: 14,
            },
            generateLabels: (chart) => {
              const originalLabels =
                Chart.defaults.plugins.legend.labels.generateLabels(chart);
              originalLabels.forEach((label) => {
                label.lineWidth = 0;
              });
              return originalLabels;
            },
          },
        },
      },
      tension: 0.1,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: "#fff",
          },
          ticks: {
            color: "#000",
            font: {
              size: 14,
            },
            lineHeight: "13px",
            callback: function (value, index, ticks) {
              return "$" + value.toFixed(1).toLocaleString();
            },
          },
          title: {
            display: true,
            text: chartData?.cashFlowChartSpecification?.y_axisTitle,
            color: "#000",
            font: {
              size: 13,
              weight: "bold",
            },
            lineHeight: "15px",
            padding: { bottom: 17 },
          },
        },
        x: {
          grid: {
            color: "#fff",
          },
          ticks: {
            color: "#000",
            font: {
              size: 14,
            },
            lineHeight: "13px",
          },
          title: {
            display: true,
            text: chartData?.cashFlowChartSpecification?.x_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "16px",
            padding: { bottom: 17 },
          },
        },
      },
    };

    const fuelExpenseOptionPdf = {
      responsive: true,
      maintainAspectRatio: true,
      barPercentage: 0.4,
      scaleLineColor: "transparent",
      plugins: {
        datalabels: {
          display: function (context) {
            return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
          },
          align: "center",
          anchor: "center",
          color: "#000",
          font: {
            size: 15,
            weight: 700,
            lineHeight: 15,
          },
          formatter: function (value, context) {
            return (
              context.dataset.label +
              ": " +
              value.toLocaleString(undefined, { maximumFractionDigits: 0 })
            );
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          color: "#000",
          font: {
            lineHeight: 13,
          },
          ticks: {
            color: "#000",
            font: {
              size: 12,
            },
            lineHeight: "13px",
          },
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            color: "#fff",
          },
          color: "#000",
          font: {
            size: 15,
            lineHeight: 13,
          },
          ticks: {
            color: "#000",
            font: {
              size: 14,
            },
            lineHeight: "13px",
            callback: function (value, index, ticks) {
              return "$" + value.toFixed(1).toLocaleString();
            },
          },
          stacked: true,
          border: {
            display: false,
          },
          beginAtZero: true,
          title: {
            display: true,
            text: chartData?.fuelExpense?.y_axisTitle,
            color: "#000",
            lineHeight: "13px",
            font: {
              size: 15,
              weight: "bold",
            },
            padding: { bottom: 17 },
          },
        },
      },
    };

    const emissionsAttributableToCombustionOptionsPdf = {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.4,
      scaleLineColor: "transparent",
      plugins: {
        datalabels: {
          display: function (context) {
            return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
          },
          align: "center",
          anchor: "center",
          color: "#000",
          font: {
            size: 15,
            weight: 700,
            lineHeight: 15,
          },
          formatter: function (value, context) {
            if (context.dataIndex === 0) {
              return (
                context.dataset.label + " : " + context.dataset.qty[0] + " mt"
              );
            } else if (context.dataIndex === 1) {
              return (
                context.dataset.label + " : " + context.dataset.qty[1] + " mt"
              );
            } else if (context.dataIndex === 2) {
              return (
                context.dataset.label + " : " + context.dataset.qty[2] + " mt"
              );
            } else if (context.dataIndex === 3) {
              return (
                context.dataset.label + " : " + context.dataset.qty[3] + " mt"
              );
            }
            return context.dataset.label + ": " + value.toLocaleString();
          },
        },
        legend: {
          position: "bottom",
          boxBorderStyle: "none",
          labels: {
            padding: 20,
            color: "#000",
            font: {
              size: 15,
              lineHeight: 14,
            },
            generateLabels: (chart) => {
              const originalLabels =
                Chart.defaults.plugins.legend.labels.generateLabels(chart);

              if (originalLabels) {
                return originalLabels;
              }
              originalLabels.forEach((label) => {
                label.lineWidth = 0;
              });
              return originalLabels;
            },
          },
        },
      },
      scales: {
        x: {
          color: "#000",
          font: {
            size: 10,
            lineHeight: 13,
          },
          ticks: {
            color: "#000",
            font: {
              size: 13,
            },
            lineHeight: "13px",
          },
          stacked: true,
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: chartData?.totalPollutionChartSpecification_gwp20
              ?.x_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
        y: {
          grid: {
            color: "#fff",
          },
          color: "#000",
          font: {
            size: 10,
            lineHeight: 13,
          },
          ticks: {
            color: "#000",
            font: {
              size: 14,
            },
            lineHeight: "13px",
          },
          stacked: true,
          border: {
            display: false,
          },
          beginAtZero: true,
          title: {
            display: true,
            text: chartData?.totalPollutionChartSpecification_gwp20
              ?.y_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
      },
    };

    const ciiOptionsPdf = {
      plugins: {
        datalabels: {
          display: true,
          color: "#ffffff",
          font: {
            size: 12,
            lineHeight: 20,
            weight: 700,
          },
          formatter: function (value, context) {
            var label = context.dataset.type === "line" ? "" : value.label;
            return label;
          },
        },
        legend: {
          position: "bottom",
          boxBorderStyle: "none",
          labels: {
            padding: 20,
            color: "#000",
            font: {
              size: 15,
              lineHeight: 14,
            },
            generateLabels: (chart) => {
              const originalLabels =
                Chart.defaults.plugins.legend.labels.generateLabels(chart);

              let labels = [];
              if (originalLabels.length > 0) {
                originalLabels.map((label) => {
                  if (typeof label.text === "string" && label.text.length > 2) {
                    labels.push(label);
                  }
                });
              }
              return labels;
            },
          },
        },
      },
      scales: {
        x: {
          color: "#000",
          font: {
            size: 10,
            lineHeight: 13,
          },
          ticks: {
            color: "#000",
            font: {
              size: 15,
            },
            lineHeight: "13px",
          },
          stacked: true,
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: chartData?.ciiChartSpecification?.x_axisTitle,
            color: "#9E9E9E",
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
        y: {
          grid: {
            color: "#374154", // Customize Y-axis grid color here
          },
          color: "#000",
          font: {
            size: 10,
            lineHeight: 13,
          },
          ticks: {
            color: "#000",
            font: {
              size: 15,
            },
            lineHeight: "13px",
          },
          stacked: false,
          border: {
            display: false,
          },
          beginAtZero: true,
          title: {
            display: true,
            text: chartData?.ciiChartSpecification?.y_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
      },
    };
    const eu_chartOptionPdf = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      tension: 0.1,
      scales: {
        y: {
          stacked: true,
          beginAtZero: true,
          grid: {
            color: "#fff",
          },
          ticks: {
            color: "#000",
            font: {
              size: 15,
            },
            lineHeight: "13px",
            callback: function (value, index, ticks) {
              if (value === 0) {
                return "$" + 0;
              } else {
                return "$" + value.toFixed(1).toLocaleString();
              }
            },
          },
          title: {
            display: true,
            text: chartData?.myCOCchart?.y_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
        x: {
          stacked: true,
          grid: {
            color: "#fff",
          },
          ticks: {
            color: "#000",
            font: {
              size: 15,
            },
            lineHeight: "13px",
          },
          title: {
            display: true,
            text: chartData?.myCOCchart?.x_axisTitle,
            color: "#000",
            font: {
              size: 15,
              weight: "bold",
            },
            lineHeight: "13px",
            padding: { bottom: 17 },
          },
        },
      },
    };

    return (
      <>
        {chartData && (
          <Container ref={ref}>
            <Container className="p-0 pb-[77px]">
              <Grid className="h-[100%]" ref={componentRef1}>
                <Grid className="w-full flex justify-center">
                  <Typography
                    variant="h4"
                    className="uppercase text-black font-bold text-center mb-10"
                  >
                    Financial
                  </Typography>
                </Grid>
                <Financial
                  customClass="bg-white"
                  textBlack="text-black !font-bold"
                  optionsCCO={yearlyFuelExpenditureOptionsPdf}
                  fuelExpenseOptions={fuelExpenseOptionPdf}
                  show={show}
                  fonSize="!text-[16px]"
                />
              </Grid>
              <Box className="mt-[77px]" ref={componentRef2}>
                <Grid className="w-full flex justify-center">
                  <Typography
                    variant="h4"
                    className="uppercase text-black font-bold text-center mt-10 mb-10"
                  >
                    Emissions
                  </Typography>
                </Grid>
                <Emissions
                  customClass="bg-white"
                  textBlack="text-black !font-bold"
                  optionsEmmission={emissionsAttributableToCombustionOptionsPdf}
                  optionsEmmission_gwp={
                    emissionsAttributableToCombustionOptionsPdf
                  }
                  compliance_myEUETSCostsOptions={
                    compliance_myEUETSCostsOptionsPdf
                  }
                  show={show}
                />
              </Box>
              <Box className="mt-[66px]" ref={componentRef3}>
                <Grid className="w-full flex justify-center">
                  <Typography
                    variant="h4"
                    className="uppercase text-black font-bold text-center mt-10 mb-10"
                  >
                    CII
                  </Typography>
                </Grid>
                <Compliance
                  customClass="bg-white"
                  textBlack="text-black"
                  optionsCII={ciiOptionsPdf}
                  show={show}
                />
              </Box>
              <Box className="mt-[66px]" ref={componentRef4}>
                <Grid className="w-full flex justify-center">
                  <Typography
                    variant="h4"
                    className="uppercase text-black font-bold text-center mt-10 mb-10"
                  >
                    EUETS Costs Chart
                  </Typography>
                </Grid>

                <EUETSCostsChart
                  customClass="bg-white"
                  textBlack="text-black !font-bold"
                  compliance_myEUETSCostsOptions={
                    compliance_myEUETSCostsOptionsPdf
                  }
                  eu_chartOption={eu_chartOptionPdf}
                  show={show}
                  textColor="text-black !font-bold !text-[15px] !leading-[1.4em]"
                  // block="!block"
                />
              </Box>
            </Container>
          </Container>
        )}
      </>
    );
  }
);
export default Pdf;
