import { useContext, useEffect, useRef } from "react";
import { Divider, Grid, Typography, Box, Button } from "@mui/material";
import { Line } from "react-chartjs-2";
import { chartContext } from "../../common/ChartContex";
import { createArrayBetween } from "../../common/formatter";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

export default function Compliance({
  customClass,
  optionsCII,
  textBlack,
  show,
}) {
  const chartData = useContext(chartContext);
  useEffect(() => {}, [chartData]);

  const compilanceChart = chartData?.ciiChartSpecification;

  const backgroundRainbowData =
    chartData?.ciiChartSpecification?.backgroundRainbow?.backgroundRainbow;

  const vesselLineData = chartData?.ciiChartSpecification?.perVesselLines;

  const compilanceYear = createArrayBetween(
    compilanceChart?.firstYear,
    compilanceChart?.lastYear
  );

  const [value, setValue] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);

  useEffect(() => {
    if (compilanceChart) {
      let ciiData = {};

      Object.values(backgroundRainbowData)?.map((data) => {
        let keyData = Object.keys(data)?.map((k) => k);

        keyData.forEach((e) => {
          if (Object.prototype.hasOwnProperty.call(ciiData, e)) {
            ciiData[e] = [...ciiData[e], data[e]];
          } else {
            ciiData[e] = [data[e]];
          }
        });
      });

      let vesselLine = vesselLineData.map((lineData) => {
        return {
          type: "line",
          label: lineData.vesselName,
          data: lineData.points.map((data) => +data.value.toFixed(2)),
          borderDash: [0, 0],
          backgroundColor: lineData?.colorHex,
          borderColor: lineData?.colorHex,
          lineTension: 0.2,
          radius: 1,
          pointRadius: 2,
          borderWidth: 3,
        };
      });

      setValue(vesselLine);

      const years = Object.keys(backgroundRainbowData);
      if(years.length === 0){
        return
      }
      const rainbowBgData = Object.keys(backgroundRainbowData[years[0]]);
      if(rainbowBgData.length === 0){
        return
      }
      const datasets = rainbowBgData.map(
        (category, index) => ({
          type: "line",
          label: String.fromCharCode(65 + index),
          data: years.map((year) => backgroundRainbowData[year][category]),
          fill: true,
          backgroundColor:
            index === 0
              ? "#C5E0B4"
              : index === 1
              ? "#E2F0D9"
              : index === 2
              ? "#FFD966"
              : index === 3
              ? "#FF6600"
              : "#FF0000",
          borderColor:
            index === 0
              ? "#C5E0B4"
              : index === 1
              ? "#E2F0D9"
              : index === 2
              ? "#FFD966"
              : index === 3
              ? "#FF6600"
              : "#FF0000",
          pointRadius: 0,
          lineTension: 0.4,
        })
      );

      setValue1(datasets);

      const yearArray = years.map((year) => parseInt(year));
      const slicedYearArray = yearArray.slice(1, 6); // slice the year Arr because we required only next 5 years array 

      let scatterData = {
        type: "scatter",
        backgroundColor: "#2c8ec6",
        pointStyle: "circle",
        pointBorderWidth: 0,
        pointRadius: 8,
        hoverRadius: 8,
        data: slicedYearArray.map((e, index) => ({
          x: e,
          y:
            index === 0
              ? (1.5 * backgroundRainbowData[e]["at_d1"]) - (0.5 * backgroundRainbowData[e]["at_d2"])
              : index === 1
              ? (backgroundRainbowData[e]["at_d2"] + backgroundRainbowData[e]["at_d1"])/2
              : index === 2
              ? (backgroundRainbowData[e]["at_d3"]+ backgroundRainbowData[e]["at_d2"])/2
              : index === 3
              ? (backgroundRainbowData[e]["at_d4"]+ backgroundRainbowData[e]["at_d3"])/2
              : index === 4
              ? (1.5 * backgroundRainbowData[e]["at_d4"]) - (0.5 * backgroundRainbowData[e]["at_d3"])
              : 0,
          label: String.fromCharCode(65 + index),
        })),
      };

      setValue2(scatterData);
    }
  }, [compilanceChart]);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [data, setData] = useState(null);

  const handleCiiChart = (title) => {
    setTitle(title);
    setData(compilanceChart.comments);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    ciiChart?.current?.scrollIntoView({ behavior: "auto" });
  };

  const ciiChart = useRef(null);
  const ciiChartInfo = useRef(null);

  useEffect(() => {
    if (open) {
      ciiChartInfo.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);

  return (
    <Grid
        ref={ciiChart}
        container
        className={`px-4 pt-[21px] pb-[22.57px] h-full  ${customClass}`}
      >
        <Box className="flex justify-between w-full">
          <Box>
            <Typography
              variant="subtitle1"
              className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
            >
              {compilanceChart?.chartTitle}
            </Typography>
            {compilanceChart?.chartSubTitle && (
              <Typography
                variant="subtitle2"
                className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
              >
                {compilanceChart?.chartSubTitle}
              </Typography>
            )}
          </Box>

          {compilanceChart?.comments && show && (
            <Box>
              <Tooltip title="Notes and Sources" arrow>
                <Button
                  type="submit"
                  onClick={() => {
                    handleCiiChart(compilanceChart?.chartTitle);
                  }}
                >
                  <img src={open ? UPImg : BottomImg} alt="Information" />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider className="w-full border border-solid border-mortar-grey my-4 " />
        <Grid item xs={12} className="compliance-chart-container">
          <Line
            data={{
              labels: compilanceYear?.map((i) => i),
              datasets: [value2, ...value, ...value1],
            }}
            options={optionsCII}
          />
        </Grid>
        {open && (
          <div ref={ciiChartInfo}>
            <Divider className="w-full border border-solid border-mortar-grey my-4" />
            <div className="log-outmodal info">
              <div className="info-header">
                <Grid className="flex justify-start items-start ">
                  <Typography
                    variant="h3"
                    className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                  >
                    {title}
                  </Typography>
                </Grid>
                <Button onClick={handleClose}>
                  <img src={CloseImg} alt="close" />
                </Button>
              </div>
              {data && (
                <div
                  className="text-base mt-[10px] info-text text-white"
                  dangerouslySetInnerHTML={{ __html: data }}
                />
              )}
            </div>
          </div>
        )}
      </Grid>
  );
}